<template>
  <layout-website>
    <template #content>
      <router-view />
    </template>
  </layout-website>
</template>

<script>
import LayoutWebsite from '@core/layouts/layout-website/LayoutWebsite.vue'


export default {
  components: {
    LayoutWebsite,
  },
}
</script>
