<template>
  <div id="app">
    <!-- Header Section -->
    <header class="main-header">
      <div class="container d-flex align-items-center justify-content-between">
        <div class="logo-container d-flex align-items-center" @click="closeMenu">
          <img :src="appLogoImage" alt="PharmaConnect Logo" class="brand-logo" width="100%" height="100%"/>
          <router-link to="/" class="logo text-primary">PharmaConnect</router-link>
        </div>
        <!-- Hamburger Menu Icon -->
        <div class="menu-icon" @click="toggleMenu">
          <feather-icon icon="MenuIcon" size="21" />
        </div>
        <nav :class="{ open: isMenuOpen }">
          <ul class="nav-list d-flex" @click="closeMenu">
            <router-link to="/" exact class="nav-link" >Accueil</router-link>
            <li ><router-link to="/about" class="nav-link">Qui Sommes-Nous?</router-link></li>
            <li ><router-link to="/services" class="nav-link">Nos Services</router-link></li>
            <li ><router-link to="/news" class="nav-link">Actualités et Nouveautés</router-link></li>
            <li ><router-link to="/contact" class="nav-link">Contactez-Nous</router-link></li>
            <li>
              <a :href="$router.resolve({ path: '/login' }).href" class="nav-link portal-link" target="_blank"
                rel="noopener noreferrer">
                Portail
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <!-- CONTENT -->
    <slot name="content"></slot>
    <!--/ Content -->
    <!-- Footer Section -->
    <footer class="footer">
      <div class="container">
        <p>COPYRIGHT &copy; 2024 DIGITHINK CONSULTING, All rights Reserved</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  data() {
    return {
      isMenuOpen: false,
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main-header {
  background-color: #ffff;
  padding: 15px 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}

.brand-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-list li {
  margin-left: 20px;
}

.nav-link {
  color: #5e5873;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 1.2rem;
}

.nav-link:hover {
  color: #ccc;
}

.router-link-active {
  font-weight: bold;
  color: #7367f0;
}

.portal-link {
  color: #7367f0;
  font-weight: bold;
  padding: 0.358rem 0.5rem;
  background-color: #6c63ff;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.portal-link:hover {
  background-color: #28c76f;
  color: #fff;
}

.menu-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
}

nav {
  display: flex;
}

nav.open {
  display: block;
}

.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  flex-shrink: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  nav {
    display: none;
  }

  nav.open {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .nav-list {
    flex-direction: column;
    padding: 10px;
  }

  .nav-list li {
    margin: 10px 0;
  }
}
</style>
